import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home/home.component';
import {
  ComponentInteractionComponent,
  NgSetupComponent,
  ProductionRoutingComponent,
  ProjectRoutingComponent
} from './posts/angular';
import {
  GitServerComponent,
  RailsSetupComponent,
  SublimeComponent
 } from './posts/dev-tools';

const routes: Routes = [
  { path: '', component: HomeComponent },
  /** Angular Posts */
  { path: 'angular/project-setup', component: NgSetupComponent },
  { path: 'angular/production-routing', component: ProductionRoutingComponent },
  { path: 'angular/application-routing', component: ProjectRoutingComponent },
  { path: 'angular/component-interaction', component: ComponentInteractionComponent },
  /** Dev Tool Posts */
  { path: 'dev-tools/git-server', component: GitServerComponent },
  { path: 'dev-tools/rails-setup', component: RailsSetupComponent },
  { path: 'dev-tools/sublime', component: SublimeComponent },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { scrollPositionRestoration: 'enabled' }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
