import { Component, OnInit } from '@angular/core';

import { Post, HCC_POSTS } from '../../models/post';

class PostCategory {
  name: string;
  count: number;
}

@Component({
  selector: 'hcc-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: PostCategory[] = [];
  constructor() { }

  ngOnInit() {
    // Create a list of unique categories and count each occurance
    for (const post of HCC_POSTS) {
      for (const category of post.categories) {
        const index = this.categories.findIndex(x => x.name === category);
        if (index >= 0) {
          this.categories[index].count++;
        } else {
          const newCategory = new PostCategory();
          newCategory.name = category;
          newCategory.count = 1;
          this.categories.push(newCategory);
        }
      }
    }
    // Sort categories by count
    this.categories.sort((a, b) => b.count - a.count);
  }

  categoryClicked(category: string) {
    console.log(`Navigate to ${category}`);
  }

}
