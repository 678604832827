import { Component, Input, OnInit } from '@angular/core';

import { Post } from '../models/post';

@Component({
  selector: 'hcc-post-intro',
  templateUrl: './post-intro.component.html',
  styleUrls: ['./post-intro.component.scss']
})
export class PostIntroComponent implements OnInit {

  @Input() post: Post = new Post();

  constructor() { }

  ngOnInit() {
  }

}
