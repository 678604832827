import { Component, OnInit } from '@angular/core';

import { Post, HCC_POSTS } from '../../../shared/models/post';

@Component({
  selector: 'hcc-ng-setup',
  templateUrl: './ng-setup.component.html',
  styleUrls: ['./ng-setup.component.scss']
})
export class NgSetupComponent implements OnInit {

  post: Post;

  /** Prefix Chapter */
  bashCode = `ng new project-name --prefix abc`;

  tslintCode = `"directive-selector": [
  true,
  "attribute",
  "hcc",
  "camelCase"
],
"component-selector": [
  true,
  "element",
  ["app", "hcc"],
  "kebab-case"
]`;

  componentCode = `@Component({
  selector: 'hcc-root',
  templateUrl: './app.component.html'
})
export class AppComponent { }`;

  indexCode = `<body>
  <hcc-root></hcc-root>
</body>`;

  jsonCode = `"root": "",
"sourceRoot": "src",
"prefix": "hcc"`;

/** Bootstrap Chapter */
  commandlineCode = `npm install -save bootstrap
npm install -save jquery    # Optional
npm install -save popper.js # Optional`;

  styleCode = `@import '~bootstrap/scss/bootstrap';`;

  configCode = `"scripts": [
  "node_modules/jquery/dist/jquery.min.js",
  "node_modules/bootstrap/dist/js/bootstrap.bundle.min.js"
]`;
/** Sass Chapter */

  ngOnInit() {
    this.post = HCC_POSTS.find(post => post.id === 'ng005');
  }
}
