import { Component, OnInit } from '@angular/core';

import { Post, HCC_POSTS } from '../../models/post';

@Component({
  selector: 'hcc-recent-posts',
  templateUrl: './recent-posts.component.html',
  styleUrls: ['./recent-posts.component.scss']
})
export class RecentPostsComponent implements OnInit {

  posts: Post[];

  constructor() { }

  ngOnInit() {
    this.posts = HCC_POSTS.slice(0, 4);
  }

}
