import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightModule } from 'ngx-highlightjs';

import {SharedModule } from '../shared/shared.module';
import {
  ComponentInteractionComponent,
  NgSetupComponent,
  ProductionRoutingComponent,
  ProjectRoutingComponent
} from './angular';
import {
  GitServerComponent,
  RailsSetupComponent,
  SublimeComponent
} from './dev-tools';

@NgModule({
  declarations: [
    NgSetupComponent,
    ProductionRoutingComponent,
    ProjectRoutingComponent,
    ComponentInteractionComponent,
    GitServerComponent,
    SublimeComponent,
    RailsSetupComponent
  ],
  imports: [
    CommonModule,
    HighlightModule,
    SharedModule
  ]
})
export class PostsModule { }
