import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  ArchiveComponent,
  CategoriesComponent,
  RecentPostsComponent,
  SideBarComponent
} from './side-bar';
import { AdsenseComponent } from './adsense/adsense.component';
import { PostIntroComponent } from './post-intro/post-intro.component';

@NgModule({
  declarations: [
    ArchiveComponent,
    CategoriesComponent,
    RecentPostsComponent,
    SideBarComponent,
    AdsenseComponent,
    PostIntroComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AdsenseComponent,
    SideBarComponent,
    PostIntroComponent
  ]
})
export class SharedModule { }
