import { Component, OnInit } from '@angular/core';

import { Post, HCC_POSTS } from '../../../shared/models/post';

@Component({
  selector: 'hcc-production-routing',
  templateUrl: './production-routing.component.html',
  styleUrls: ['./production-routing.component.scss']
})
export class ProductionRoutingComponent implements OnInit {

  postId = 'ng004';
  post: Post;

  ngOnInit() {
    this.post = HCC_POSTS.find(post => post.id === this.postId);
  }

}
