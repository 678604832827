import { Component, OnInit } from '@angular/core';

import { Post, HCC_POSTS } from '../../../shared/models/post';

@Component({
  selector: 'hcc-sublime',
  templateUrl: './sublime.component.html',
  styleUrls: ['./sublime.component.scss']
})
export class SublimeComponent implements OnInit {

  postId = 'dev001';
  post: Post;

  terminalCode = `ln -s "/Applications/Sublime Text.app/Contents/SharedSupport/bin/subl" /usr/local/bin/sublime`;
  stringEncodeCode = `Package Control: Install Package
StringEncode`;
  bootstrapCode = `Package Control: Install Package
Bootstrap 4 Snippets`;
  scssCode = `Package Control: Install Package
Syntax Highlighting For Sass`;

  constructor() { }

  ngOnInit() {
    this.post = HCC_POSTS.find(post => post.id === this.postId);
  }

}
