import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hcc-adsense',
  templateUrl: './adsense.component.html',
  styleUrls: ['./adsense.component.scss']
})
export class AdsenseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
