import { Component, OnInit } from '@angular/core';

import { Post, HCC_POSTS } from '../../../shared/models/post';

@Component({
  selector: 'hcc-rails-setup',
  templateUrl: './rails-setup.component.html',
  styleUrls: ['./rails-setup.component.scss']
})
export class RailsSetupComponent implements OnInit {

  postId = 'rails001';
  post: Post;

  homebrewCode = `ruby -e "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/master/install)"`;
  rvmCode = `curl -sSL https://get.rvm.io | bash`;
  rvmListCode = `rvm list known`;
  rubyInstallCode = `rvm install ruby-head`;
  rubyOldCode = `rvm install ruby-2.0.0`;
  rvmListAllCode = `rvm list`;
  gemVersionCode = `gem --version`;
  railsInstallCode = `gem install rails`;
  sqlVersionCode = `sqlite3 --version`;

  constructor() { }

  ngOnInit() {
    this.post = HCC_POSTS.find(post => post.id === this.postId);
  }

}
