import { Component, OnInit } from '@angular/core';

import { Post, HCC_POSTS } from '../../shared/models/post';

@Component({
  selector: 'hcc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  posts: Post[] = HCC_POSTS;
  dateFilter: Date;
  categoryFilter = '';

  constructor() { }

  ngOnInit() {
    console.log(JSON.stringify(this.posts));
    this.sortByDate();
  }

  sortByDate() {
    this.posts.sort((a, b) => a.date < b.date ? 1 : -1);
  }

  numberOfPages(): number {
    const fullPages = Math.floor(this.posts.length / 6);
    const partialPages = this.posts.length % 6 > 0 ? 1 : 0;
    console.log(`${this.posts.length} posts = ${fullPages + partialPages} pages`);
    return fullPages + partialPages;
  }

  applyCategoryFilter(category: string) {
    this.posts = HCC_POSTS.filter(post => post.categories.includes(category));
    console.log(JSON.stringify(this.posts));
  }

  applyDateFilter() {

  }

}
