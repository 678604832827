import { Component, OnInit } from '@angular/core';

import { Post, HCC_POSTS } from '../../../shared/models/post';

@Component({
  selector: 'hcc-git-server',
  templateUrl: './git-server.component.html',
  styleUrls: ['./git-server.component.scss']
})
export class GitServerComponent implements OnInit {


  postId = 'git002';
  post: Post;

  gitDirCode = `/home/user-dir/git/`;
  bareCode = `git clone --bare myProject /Target-Dir/Project-Repos/Bare/myProject.git`;
  archiveCode = `tar czf myProject.tar.gz myProject.git`;
  scpCode = `scp /path/to/myProject.tar.gz user-name@server.ip:/home/user-dir/git/myProject.tar.gz`;
  unarchiveCode = `tar -zxvf myProject.tar.gz`;
  remoteCode = `git remote add myProject user-name@server.ip:/home/user-dir/git/myProject.git`;
  pushPullCode = `git push myProject
git pull myProject`;
  cloneCode = `git clone user-name@server.ip:/home/user-dir/git/myProject.git`;
  deployDirectoryCode = `/home/user-dir/www/apps/`;
  deployCloneCode = `git clone ~/git/myProject.git`;

  ngOnInit() {
    this.post = HCC_POSTS.find(post => post.id === this.postId);
  }

}
