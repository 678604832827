import { Component, OnInit, Input } from '@angular/core';

import { Post } from '../../shared/models/post';

@Component({
  selector: 'hcc-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss']
})
export class PostCardComponent implements OnInit {

  @Input() post: Post = new Post();
  @Input() size = 'lg';

  constructor() { }

  ngOnInit() {
  }

}
