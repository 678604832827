export class Post {
  id = '';
  title = '';
  description = '';
  date = '';
  updated = '';
  categories: string[] = [];
  route: string;
  links: Link[] = [];
}

export class Link {
  name: string;
  url: string;
}

export const HCC_POSTS: Post[] = [
  {
    id: 'rails001',
    title: 'Setting up a Rails Development Environment',
    description: `Getting started with Ruby on Rails is easy, but can be intimidating because of the multitude of ways that the development
    environment can be setup.  When setting up any development environment there are a few general tips to follow.`,
    date: '2015.12.9',
    updated: '',
    categories: ['rails', 'dev tools'],
    route: '/dev-tools/rails-setup',
    links: []
  },
  {
    id: 'dev001',
    title: 'Sublime Text',
    description: `Sublime Text is a great text editing tool for use in software development of all kinds.  While Sublime Text is pretty much
    perfect right out of the box there are ways it can be improved, which makes it awesome!`,
    date: '2015.12.9',
    updated: '',
    categories: ['dev tools'],
    route: '/dev-tools/sublime',
    links: []
  },
  {
    id: 'git002',
    title: 'Creating a git repository on your own server',
    description: `Sometimes you might want to store git repositories on your own server rather than use services from Github or Bitbucket.
    I typically use a Virtual Private Server running Ubuntu Server for my Rails application repositories. All you need is a directory to be
    the home to all of your repos.`,
    date: '2015.12.9',
    updated: '',
    categories: ['git', 'dev tools'],
    route: '/dev-tools/git-server',
    links: []
  },
  {
    id: 'ng005',
    title: 'Angular Project Setup',
    description: `Angular makes project creation quite simple, but there are still customizations can be applied to each new application.`,
    date: '2019.12.24',
    updated: '',
    categories: ['Angular'],
    route: '/angular/project-setup',
    links: []
  },
  {
    id: 'ng004',
    title: 'Angular Production Routing',
    description: `So you're routing works locally but returns 404's when deployed?  Not a problem!`,
    date: '2019.11.24',
    updated: '',
    categories: ['Angular'],
    route: '/angular/production-routing',
    links: []
  },
  {
    id: 'ng003',
    title: 'Angular Application Routing',
    description: `Donec lobortis risus a elit. Etiam tempor. Ut ullamcorper, ligula eu tempor congue, eros est euismod turpis, id tincidunt
    sapien risus a quam. Maecenas fermentum consequat mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, aliquet nec,
    commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum aliquet, felis nisl adipiscing sapien, sed malesuada diam lacus
    eget erat. Cras mollis scelerisque nunc. Nullam arcu. Aliquam consequat. Curabitur augue lorem, dapibus quis, laoreet et, pretium ac,
    nisi. Aenean magna nisl, mollis quis, molestie eu, feugiat in, orci. In hac habitasse platea dictumst.`,
    date: '2019.10.24',
    updated: '',
    categories: ['Angular'],
    route: '/angular/application-routing',
    links: []
  },
  {
    id: 'ng002',
    title: 'Angular Component Interaction',
    description: `Pellentesque malesuada nulla a mi. Duis sapien sem, aliquet nec, commodo eget, consequat quis, neque. Aliquam faucibus,
    elit ut dictum aliquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat. Cras mollis scelerisque nunc. Nullam arcu.
    Aliquam consequat. Curabitur augue lorem, dapibus quis, laoreet et, pretium ac, nisi. Aenean magna nisl, mollis quis, molestie eu,
    feugiat in, orci. In hac habitasse platea dictumst.`,
    date: '2019.9.24',
    updated: '',
    categories: ['Angular'],
    route: 'angular/component-interaction',
    links: []
  },
  {
    id: 'ios001',
    title: 'Swift Camera Demo',
    description: 'Description 5',
    date: '2019.8.24',
    updated: '',
    categories: ['iOS', 'Swift'],
    route: '',
    links: []
  },
  {
    id: 'ng001',
    title: 'D3 and Angular',
    description: 'Description 6',
    date: '2019.7.24',
    updated: '',
    categories: ['Angular'],
    route: '',
    links: []
  },
  {
    id: 'git001',
    title: 'Git Cheat Sheet',
    description: 'Description 6',
    date: '2019.7.24',
    updated: '',
    categories: ['git'],
    route: '',
    links: []
  }
];
