import { Component, OnInit } from '@angular/core';

import { Post, HCC_POSTS } from '../../../shared/models/post';

@Component({
  selector: 'hcc-component-interaction',
  templateUrl: './component-interaction.component.html',
  styleUrls: ['./component-interaction.component.scss']
})
export class ComponentInteractionComponent implements OnInit {

  postId = 'ng002';
  post: Post;

  ngOnInit() {
    this.post = HCC_POSTS.find(post => post.id === this.postId);
  }

}
